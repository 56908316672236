// Listen for changes in the flow to capture the debt amount, monthly income, and zip code
window.addEventListener('heyflow-change', (event) => {
    const ownHomeData = event.detail.fieldsSimple['ownhome'];
    if (ownHomeData) {
        sessionStorage.setItem('ownHome', ownHomeData);
        console.log('ownHome:', ownHomeData);
    }

    const timeLineData = event.detail.fieldsSimple['timeline'];
    if (timeLineData) {
        sessionStorage.setItem('timeLine', timeLineData);
        console.log('timeLine:', timeLineData);
    }
    
    const zipCodeData = event.detail.fieldsSimple['zip'];
    if (zipCodeData) {
        sessionStorage.setItem('zipCode', zipCodeData);
        console.log('zipCode:', zipCodeData);
    }

    const interestLevelData = event.detail.fieldsSimple['interested'];
    if (interestLevelData) {
        sessionStorage.setItem('interestLevel', interestLevelData);
        console.log('interestLevel:', interestLevelData);
    }
});

window.addEventListener('heyflow-screen-view', (event) => {
    // Check if the current screen is the personal info screen
    // Adjust "screenName" if HeyFlow uses a different property name
    if (event.detail.screenName === 'personal-info') {
        let shadowRoot = document.querySelector(`[flow-id="${event.detail.flowID}"]`)?.shadowRoot || document;
        const tcpaTextContainer = shadowRoot.getElementById('tcpa-text');

        if (tcpaTextContainer) {
            const fetchTCPAData = () => {
                const ownHome = sessionStorage.getItem('ownHome') || '0';
                const timeLine = sessionStorage.getItem('timeLine') || '0';
                const zipCode = sessionStorage.getItem('zipCode') || '0';
                const interestLevel = sessionStorage.getItem('interestLevel') || '0';

                const apiKey = '39dkj37ejriruj4';
                const button = 'SUBMIT';
                const websiteID = '14';
                const url = `https://dynamictcpa.com/api/tcpaScript.php?apikey=${apiKey}&button=${button}&websiteID=${websiteID}&interest=${interestLevel}&own_home=${ownHome}&when=${timeLine}&zip_code=${zipCode}`;

                fetch(url)
                .then(response => response.json())
                .then(data => {
                    tcpaTextContainer.innerHTML = data.tcpa;

                    // Set the value of the existing hidden input field for brandCode
                    const brandCode = data.brandCode;
                    const brandCodeInput = shadowRoot.getElementById('brandCode');
                    if (brandCodeInput) {
                        brandCodeInput.value = brandCode;
                    } else {
                        console.error('Hidden input field for brandCode not found.');
                    }

                    // Redirect if brandCode is 'default'
                    if (brandCode === 'default') {
                        window.location.href = 'https://walkintubadvice.com/quote/noqualify.php';
                        return;
                    }

                    // Update brandName and brandImage
                    const brandNameContainer = shadowRoot.getElementById('brand-name');
                    if (brandNameContainer) {
                        brandNameContainer.innerText = data.brandName;
                    } else {
                        console.error('Brand name container not found.');
                    }

                    const brandImageContainer = shadowRoot.getElementById('brand-image');
                    if (brandImageContainer) {
                        if (data.brandImage) {
                            brandImageContainer.innerHTML = `<img src="${data.brandImage}" alt="${data.brandName}" style="display: block; margin: 0 auto;">`;
                        }
                    } else {
                        console.error('Brand image container not found.');
                    }
                })
                .catch(error => {
                    console.error('Failed to fetch TCPA data:', error);
                    tcpaTextContainer.innerText = 'Unable to load TCPA information.';
                });
            };

            // Call fetchTCPAData only if on the correct screen
            fetchTCPAData();
        }
    }
});